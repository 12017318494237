import React from 'react';
import {
  RecoilRoot
} from 'recoil';
import { CoreControl } from './CoreControl';

export function App() {
  return (
    <RecoilRoot>
      <CoreControl />
    </RecoilRoot>
  );
}