import React from 'react';
import { BackendService } from 'services/BackendService'
import { Bot, Locale } from 'out/models'
import { atom, useRecoilState } from 'recoil';

const botAtom = atom<Array<Bot>>({
  key: 'server-bots',
  default: [],
})
const localeAtom = atom<Array<Locale>>({
  key: 'server-locales',
  default: [],
})
const counterAtom = atom<number>({
  key: 'con-counter',
  default: 0,
})

let eventSource: any = null

export function CoreControl() {
  const [serverBots, setServerBots] = useRecoilState(botAtom);
  const [serverLocales, setServerLocales] = useRecoilState(localeAtom);
  const [connectionCount, setConnectionCount] = useRecoilState(counterAtom);

  async function eventSourceUpdate(event: MessageEvent<any>, prevConCount: number) {
      setConnectionCount((prevVal) => prevVal + 1)
      await getBots()
      await getLocales()
  }

  async function connect() {
    console.log("connect")

    if (eventSource == null || eventSource.readyState == EventSource.CLOSED) {
      let newEmitter = BackendService.connect()
  
        newEmitter.addEventListener("message", (event: MessageEvent<any>) => {
          eventSourceUpdate(event, connectionCount)
        })

        eventSource = newEmitter as EventSource
        console.log("Setting Event Source")
    }
  }

  async function disconnect() {
    console.log("disconnect")
    console.log(eventSource.readyState === EventSource.OPEN)
    eventSource.close()
    console.log(eventSource.readyState === EventSource.CLOSED)
    setConnectionCount(0)
  }

  async function addBot() {
    await BackendService.addBot()
  }

  async function getBots() {
    setServerBots(await BackendService.getBots())
  }

  async function addLocale() {
    await BackendService.addLocale()
  }

  async function getLocales() {
    setServerLocales(await BackendService.getLocales())
  }

  async function removeBot(id: string) {
    await BackendService.removeBot(id)
  }

  async function removeLocale(id: string) {
    await BackendService.removeLocale(id)
  }

  return (
    <div>
      <div>
        Connection Counter: {connectionCount}
      </div>
      <div>
        <button onClick={connect}>
          Connect
        </button>
      </div>
      <div>
        <button onClick={disconnect}>
          Disconnect
        </button>
      </div>
      <div>
        <button onClick={addBot}>
          Add Bot
        </button>
      </div>
      <div>
        <button onClick={addLocale}>
          Add Locale
        </button>
      </div>
      <div>
        <button onClick={getLocales}>
          Get Locales
        </button>
      </div>
      <div>
        BOTS
        <ul>
          {serverBots.map((bot, index) =>
            <li key={index}>
              <div>
                {bot.botId}
                <button style={{margin: "10px"}} onClick={async () => removeBot(bot.botId)}>
                  REMOVE
                </button>
              </div>
            </li>
          )}
        </ul>
      </div>
      <div>
        LOCALES
        <ul>
          {serverLocales.map((locale, index) =>
            <li key={index}>
              <div>
                {locale.localeId}
                <button style={{margin: "10px"}} onClick={async () => removeLocale(locale.localeId)}>
                  REMOVE
                </button>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}