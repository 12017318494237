import { Configuration } from 'out'
import { DefaultApi } from 'out/apis/DefaultApi'
import { Bot, Locale } from 'out/models'

const testUrl = "https://localhost:8080"
const prodUrl = "https://api.botspace.io:443"
const targetUrl = process.env.DEPLOY_ENV == "PROD" ? testUrl : prodUrl

export const BackendService = {
    connect: function(): EventSource {
        return new EventSource(prodUrl + "/sse?id=1")
    },

    getBots: async function(): Promise<Bot[]> {
        let bots = await apiAccessor.getBots()
        return bots
    },
    
    addBot: async function(): Promise<void> {
        await apiAccessor.postBots()
    },

    removeBot: async function(botId: string): Promise<void> {
        await apiAccessor.deleteBotsWithBotid({
            botId: botId
        })
    },

    getLocales: async function(): Promise<Locale[]> {
        let locales = await apiAccessor.getLocales()
        return locales
    },
    
    addLocale: async function(): Promise<void> {
        await apiAccessor.postLocales()
    },

    removeLocale: async function(localeId: string): Promise<void> {
        await apiAccessor.deleteLocalesWithLocaleid({
            localeId: localeId
        })
    },

    startClock: async function(): Promise<void> {
        await apiAccessor.postGamesStart()
    },

    stopClock: async function(): Promise<void> {
        await apiAccessor.postGamesEnd()
    },

    fillLocales: async function(): Promise<void> {
        await apiAccessor.postGamesFill()
    }
}

const apiAccessor = new DefaultApi(new Configuration({
    "basePath": prodUrl
}))