/* tslint:disable */
/* eslint-disable */
/**
 * botspace
 * My Application
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Bot,
    BotFromJSON,
    BotToJSON,
    Locale,
    LocaleFromJSON,
    LocaleToJSON,
} from '../models';

export interface DeleteBotsWithBotidRequest {
    botId: string;
}

export interface DeleteLocalesWithLocaleidRequest {
    localeId: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Delete bots with botid
     */
    async deleteBotsWithBotidRaw(requestParameters: DeleteBotsWithBotidRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.botId === null || requestParameters.botId === undefined) {
            throw new runtime.RequiredError('botId','Required parameter requestParameters.botId was null or undefined when calling deleteBotsWithBotid.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bots/{botId}`.replace(`{${"botId"}}`, encodeURIComponent(String(requestParameters.botId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete bots with botid
     */
    async deleteBotsWithBotid(requestParameters: DeleteBotsWithBotidRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteBotsWithBotidRaw(requestParameters, initOverrides);
    }

    /**
     * Delete locales with localeid
     */
    async deleteLocalesWithLocaleidRaw(requestParameters: DeleteLocalesWithLocaleidRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.localeId === null || requestParameters.localeId === undefined) {
            throw new runtime.RequiredError('localeId','Required parameter requestParameters.localeId was null or undefined when calling deleteLocalesWithLocaleid.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/locales/{localeId}`.replace(`{${"localeId"}}`, encodeURIComponent(String(requestParameters.localeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete locales with localeid
     */
    async deleteLocalesWithLocaleid(requestParameters: DeleteLocalesWithLocaleidRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteLocalesWithLocaleidRaw(requestParameters, initOverrides);
    }

    /**
     * Get bots
     */
    async getBotsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<Bot>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bots`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BotFromJSON));
    }

    /**
     * Get bots
     */
    async getBots(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<Bot>> {
        const response = await this.getBotsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get locales
     */
    async getLocalesRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<Locale>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/locales`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocaleFromJSON));
    }

    /**
     * Get locales
     */
    async getLocales(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<Locale>> {
        const response = await this.getLocalesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get sse
     */
    async getSseRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get sse
     */
    async getSse(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.getSseRaw(initOverrides);
    }

    /**
     * Post bots
     */
    async postBotsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bots`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post bots
     */
    async postBots(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.postBotsRaw(initOverrides);
    }

    /**
     * Post games end
     */
    async postGamesEndRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/end`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post games end
     */
    async postGamesEnd(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.postGamesEndRaw(initOverrides);
    }

    /**
     * Post games fill
     */
    async postGamesFillRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/fill`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post games fill
     */
    async postGamesFill(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.postGamesFillRaw(initOverrides);
    }

    /**
     * Post games restart
     */
    async postGamesRestartRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/restart`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post games restart
     */
    async postGamesRestart(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.postGamesRestartRaw(initOverrides);
    }

    /**
     * Post games start
     */
    async postGamesStartRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post games start
     */
    async postGamesStart(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.postGamesStartRaw(initOverrides);
    }

    /**
     * Post locales
     */
    async postLocalesRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/locales`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post locales
     */
    async postLocales(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.postLocalesRaw(initOverrides);
    }

}
