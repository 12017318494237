/* tslint:disable */
/* eslint-disable */
/**
 * botspace
 * My Application
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Bot
 */
export interface Bot {
    /**
     * 
     * @type {string}
     * @memberof Bot
     */
    botIdF9R1nwE?: string;
    /**
     * 
     * @type {string}
     * @memberof Bot
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Bot
     */
    botId: string;
    /**
     * 
     * @type {string}
     * @memberof Bot
     */
    localeId: string;
}

export function BotFromJSON(json: any): Bot {
    return BotFromJSONTyped(json, false);
}

export function BotFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'botIdF9R1nwE': !exists(json, 'botId-f9R1nwE') ? undefined : json['botId-f9R1nwE'],
        'id': json['id'],
        'botId': json['botId'],
        'localeId': json['localeId'],
    };
}

export function BotToJSON(value?: Bot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'botId-f9R1nwE': value.botIdF9R1nwE,
        'id': value.id,
        'botId': value.botId,
        'localeId': value.localeId,
    };
}

