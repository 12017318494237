/* tslint:disable */
/* eslint-disable */
/**
 * botspace
 * My Application
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Locale
 */
export interface Locale {
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    localeIdPgr1VBg?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    localeId: string;
}

export function LocaleFromJSON(json: any): Locale {
    return LocaleFromJSONTyped(json, false);
}

export function LocaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Locale {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'localeIdPgr1VBg': !exists(json, 'localeId-pgr1VBg') ? undefined : json['localeId-pgr1VBg'],
        'localeId': json['localeId'],
    };
}

export function LocaleToJSON(value?: Locale | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'localeId-pgr1VBg': value.localeIdPgr1VBg,
        'localeId': value.localeId,
    };
}

